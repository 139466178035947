<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <template slot="title">
            <strong>Data Kredit</strong>
            <a-button-group class="ml-2">
          <a-button icon="plus-circle" class="text-primary" type=""
          @click="connector('anggota')">
            Tambah
          </a-button>
          <a-tooltip placement="top">
            <template slot="title">
              <span>Refresh Data</span>
            </template>
            <a-button @click="getAllData(true)">
              <a-icon type="sync" :spin="loading" class="text-success"/>
            </a-button>
          </a-tooltip>
          <!-- <a-tooltip placement="top">
            <template slot="title">
              <span>PDF Download</span>
            </template>
            <a-button @click="pdfMutasiTabungan">
              <a-icon type="file-pdf" class="text-warning"/>
            </a-button>
          </a-tooltip> -->
          <!-- <a-tooltip placement="top">
            <template slot="title">
              <span>Setting Pengesahan</span>
            </template>
            <a-button @click="showModalPengesahan">
              <a-icon type="setting" class="text-secondary"/>
            </a-button>
          </a-tooltip> -->
            <!-- <a-dropdown>
              <a-button
                class="text-success"
                :loading="importBtnLoading"
                ><a-icon type="file-excel" class="text-success"/></a-button
              >
              <a-menu slot="overlay">
                <a-menu-item key="1" class="text-success" @click="exportExcel">
                  <a-icon type="file-excel" />Export Excel
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            <input
              type="file"
              ref="excelFileUpload"
              style="display: none;"
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @input="getSheetExcel"
            /> -->
          <!-- <a-tooltip placement="top">
            <template slot="title">
              <span>Export Data</span>
            </template>
            <a-button @click="exportExcel">
              <a-icon type="file-excel" class="text-success"/>
            </a-button>
          </a-tooltip> -->
        </a-button-group>
          </template>

          <a-table
            :scroll="{ x: 3500 }"
            :columns="columns"
            :dataSource="datatable"
            style="padding-top: -25px"
            :pagination="{
              hideOnSinglePage: true,
              defaultPageSize: 10,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }"
            size="small"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            ></a-icon>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>
            <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
            <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
            <span slot="tags" slot-scope="tags">
              <a-tag v-for="tag in tags" color="blue" :key="tag">{{
                tag
              }}</a-tag>
            </span>
            <span slot="action" slot-scope="text, record">
              <!-- <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="registerMobileBanking(record.id)"> <a-icon type="user" />Register Mobile Banking</a-menu-item>
              <a-menu-item key="2" @click="phoneNumberMobileBankingModal(record.id, 'open')"> <a-icon type="number" />Change Phone Number</a-menu-item>
              <a-menu-item key="3" @click="resetMobileBanking(record.id)"> <a-icon type="reload" />Reset Phone Number</a-menu-item>
            </a-menu>
            <a class="text-primary">
              <a-icon type="menu"></a-icon>
            </a>
          </a-dropdown> -->
              <!-- <a-button style="margin-left: 8px"> Button <a-icon type="down" /> </a-button> -->
              <a class="text-warning" @click="sendToEdit(record)">
                <a-icon type="edit"></a-icon>
              </a>
              <!-- <a-divider type="vertical" />
              <a class="text-success" @click="cetakValidasi(record)" href="javascript:void(0)">
                <a-icon type="printer"></a-icon>
              </a> -->
              <a-divider type="vertical" />
              <a
                href="javascript:void(0)"
                class="text-danger"
                @click="showDeleteConfirm(record)"
              >
                <a-icon type="delete"></a-icon>
              </a>
              <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
            </span>
            <span slot="kodetransaksi" slot-scope="text, record">
              {{
                findRelationData(
                  record,
                  "masterkodeTransaksi",
                  "kode",
                  "kodetransaksi",
                  "keterangan"
                )
              }}
            </span>
            <!-- <span slot="printed" slot-scope="text">
              <a-tag :color="text === 0 ? 'secondary' : 'green'">{{ text === 0 ? 'Not Printed' : 'Printed' }}</a-tag>
            </span> -->
            <span slot="tgl" slot-scope="text">
              {{ formatDate(text) }}
            </span>
            <span slot="created_at" slot-scope="text">
              {{ formatDate(text, 'YYYY-MM-DDThh:mm:ss', 'DD-MM-YYYY hh:mm:ss') }}
            </span>
            <span slot="numberFormat" slot-scope="text">
              {{ numberFormat(text) }}
            </span>
          </a-table>
        </a-card>
      </div>
    </div>
    <!-- <modalpengesahan ref="modalPengesahan"/> -->
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
// import modalpengesahan from '../settings/modalpengesahan.vue'
/* End Script Import Script */
export default {
  components: {
    // modalpengesahan,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    return {
      theme: this.$store.state.settings.theme,
      loading: false,
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tablesimpanan',
      visible: false,
      columns: [
        {
          title: 'Action',
          scopedSlots: { customRender: 'action' },
          width: 70,
          fixed: 'left',
        },
        {
          title: 'Kode',
          dataIndex: 'kode',
          width: 70,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.kode.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Rekening',
          dataIndex: 'rekening',
          width: 100,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.rekening.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Tanggal',
          width: 70,
          dataIndex: 'tgl',
          scopedSlots: { customRender: 'tgl' },
        },
        {
          title: 'Nama',
          width: 200,
          dataIndex: 'nama',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          // width: 70,
        },
        {
          title: 'Alamat',
          width: 250,
          dataIndex: 'alamat',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.alamat
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          // width: 70,
        },
        {
          title: 'Telepon',
          width: 170,
          dataIndex: 'telepon',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.telepon
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          // width: 70,
        },
        {
          title: 'Rekening Tabungan',
          width: 200,
          dataIndex: 'rekeningtabungan',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.rekeningtabungan
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          // width: 70,
        },
        {
          title: 'Nama AO',
          width: 70,
          dataIndex: 'nama_ao',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama_ao
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          // width: 100,
        },
        {
          title: 'caraperhitungan',
          width: 70,
          dataIndex: 'caraperhitungan',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
        {
          title: 'golongankredit',
          width: 70,
          dataIndex: 'golongankredit',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
        {
          title: 'golongankelompok',
          width: 70,
          dataIndex: 'golongankelompok',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
        {
          title: 'nospk',
          width: 70,
          dataIndex: 'nospk',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
        {
          title: 'sukubunga',
          width: 70,
          dataIndex: 'sukubunga',
          scopedSlots: { customRender: 'sukubunga' },
          // width: 100,
        },
        {
          title: 'plafond',
          width: 70,
          dataIndex: 'plafond',
          scopedSlots: { customRender: 'numberFormat' },
          // width: 100,
        },
        {
          title: 'totalbunga',
          width: 70,
          dataIndex: 'totalbunga',
          scopedSlots: { customRender: 'numberFormat' },
          // width: 100,
        },
        {
          title: 'uangmuka',
          width: 70,
          dataIndex: 'uangmuka',
          scopedSlots: { customRender: 'numberFormat' },
          // width: 100,
        },
        {
          title: 'lama',
          width: 70,
          dataIndex: 'lama',
          scopedSlots: { customRender: 'numberFormat' },
          // width: 100,
        },
        {
          title: 'graceperiod',
          width: 70,
          dataIndex: 'graceperiod',
          scopedSlots: { customRender: 'numberFormat' },
          // width: 100,
        },
        {
          title: 'graceperiodbahas',
          width: 70,
          dataIndex: 'graceperiodbahas',
          scopedSlots: { customRender: 'numberFormat' },
          // width: 100,
        },
        {
          title: 'administrasi',
          width: 70,
          dataIndex: 'administrasi',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
        {
          title: 'materai',
          width: 70,
          dataIndex: 'materai',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
        {
          title: 'notaris',
          width: 70,
          dataIndex: 'notaris',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
        {
          title: 'persenprovisi',
          width: 70,
          dataIndex: 'persenprovisi',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
        {
          title: 'provisi',
          width: 70,
          dataIndex: 'provisi',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
        {
          title: 'asuransi',
          width: 70,
          dataIndex: 'asuransi',
          scopedSlots: { customRender: 'numberFormat' },
          // width: 100,
        },
        {
          title: 'danasendiri',
          width: 70,
          dataIndex: 'danasendiri',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
        {
          title: 'hargaperolehan',
          width: 70,
          dataIndex: 'hargaperolehan',
          scopedSlots: { customRender: 'numberFormat' },
          // width: 100,
        },
        {
          title: 'um',
          width: 70,
          dataIndex: 'um',
          scopedSlots: { customRender: 'numberFormat' },
          // width: 100,
        },
        {
          title: 'premiasuransi',
          width: 70,
          dataIndex: 'premiasuransi',
          scopedSlots: { customRender: 'numberFormat' },
          // width: 100,
        },
        {
          title: 'nopolis',
          width: 70,
          dataIndex: 'nopolis',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
        {
          title: 'ujroh',
          width: 70,
          dataIndex: 'ujroh',
          scopedSlots: { customRender: 'numberFormat' },
          // width: 100,
        },
        {
          title: 'tabaruk',
          width: 70,
          dataIndex: 'tabaruk',
          scopedSlots: { customRender: 'numberFormat' },
          // width: 100,
        },
        {
          title: 'autodebet',
          width: 70,
          dataIndex: 'autodebet',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
        {
          title: 'username',
          width: 70,
          dataIndex: 'username',
          scopedSlots: { customRender: '' },
          // width: 100,
        },
      ],
      masterkodeTransaksi: [],
      backupmasterkodeTransaksi: [],
      datatable: [],
      componentKey: 0,
    }
  },
  /* End Data variable vue */
  mounted: function () {
    this.gridOptionsModalExcel = {}
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    localStorage.removeItem('minibankeditkreditdata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    this.getAllData()
    // this.getAgamaF()
  },
  methods: {
    moment,
    // Modal func
    // showModalPengesahan() {
    //   this.$refs.modalPengesahan.showModal()
    // },
    // End of Modal func

    /* this is direct download for pdf without preview it first */
    async pdfMutasiTabungan() {
      var res = await lou.ngetDocumentPdf('registrasi/pembiayaan?preview=pdf', false, false)
      if (res) {
      // console.log("Success", response);
        const blob = new Blob([res], {
          type: 'application/pdf',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.target = '_blank'
        // link.download = filename

        // link.download = ''
        link.click()
        // const objectUrl = URL.createObjectURL(blob)
        // console.log('objectUrl', objectUrl)
        // this.amodal.ArrayBuffer = objectUrl
        URL.revokeObjectURL(blob)
        setTimeout(() => {
          this.componentKey += 1
          // this.amodalvisible = true
        }, 500)
      }
    },
    async cetakValidasi(record) {
      var res = await lou.ngetDocumentPdf('registrasi/pembiayaan/cetak/' + record.id, false, false)
      if (res) {
      // console.log("Success", response);
        const blob = new Blob([res], {
          type: 'application/pdf',
        })
        var iframe = this._printIframe
        var url = URL.createObjectURL(blob)
        if (!this._printIframe) {
          iframe = this._printIframe = document.createElement('iframe')
          document.body.appendChild(iframe)

          iframe.style.display = 'none'
          iframe.onload = function() {
            setTimeout(function() {
              iframe.focus()
              iframe.contentWindow.print()
            }, 1)
          }
        }

        iframe.src = url
        // const link = document.createElement('a')
        // link.href = URL.createObjectURL(blob)
        // // link.target = '_blank'
        // // link.download = filename

        // link.download = ''
        // link.click()
        // // const objectUrl = URL.createObjectURL(blob)
        // // console.log('objectUrl', objectUrl)
        // // this.amodal.ArrayBuffer = objectUrl
        // URL.revokeObjectURL(blob)
        setTimeout(() => {
          this.componentKey += 1
          // this.amodalvisible = true
        }, 500)
      }
    },
    connector() {
      // console.log('keanggotaan', keanggotaan)
      localStorage.setItem(
        'minibankeditkreditdata',
        JSON.stringify({ id: '' }),
      )
      this.$router.push('/minibank/kredit/form')
    },
    rangedofunction(date, dateString) {
      this.tgl1 = moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.tgl2 = moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
      // console.log('date', date)
      // console.log('dateString', dateString)
      // var res = this.
      // this.getAllData()
    },
    async getAllData(onclick = false) {
      this.loading = true
      // if (!onclick) {
      //   var kodetransaksi = await lou.customUrlGet2('registrasi/pembiayaan/kode_transaksi')
      //   if (kodetransaksi) {
      //     this.masterkodeTransaksi = this.$g.clone(kodetransaksi.data)
      //     this.backupmasterkodeTransaksi = this.$g.clone(kodetransaksi.data)
      //   }
      // }
      var response = await lou.customUrlGet2('registrasi/pembiayaan')
      if (response) {
        this.datatable = response.data
        this.loading = false
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      } else {
        this.loading = false
      }
      // console.log('this.datatable', this.datatable)
    },
    sendToEdit(edata) {
      // var ndata = {}
      // for (let i = 0; i < this.columns.length; i++) {
      //   const element = this.columns[i].dataIndex
      //   ndata[element.toString().toLowerCase()] = edata[element]
      // }
      // console.log('edata', edata)
      localStorage.setItem(
        'minibankeditkreditdata',
        JSON.stringify(edata),
      )
      this.$router.push('/minibank/kredit/form')
    },
    formatDate(value, formatFrom = 'YYYY-MM-DD', formatTo = 'DD-MM-YYYY') {
      console.log('value', value)
      return moment(value, formatFrom).format(formatTo)
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2(
            'registrasi/pembiayaan/' + deldata.id,
          )
          // this.datatable = response.data
          if (response) {
            this.getAllData()
          } else {
          }
        },
        onCancel: () => {
          // console.log('Cancel')
        },
      })
    },
    findRelationData(data, mastername, key, datakey, label) {
      // console.log('mastername', mastername)
      var target = this[mastername].findIndex((x) => x[key] === data[datakey])
      // console.log('data', data)
      // console.log('mastername', mastername)
      // console.log('key', key)
      // console.log('target', target)
      // console.log('this.allMaster[mastername][target]', this.allMaster[mastername][target])
      // console.log('this[mastername][target]', this[mastername][target])
      return target === -1
        ? 'Data tidak ditemukan'
        : this[mastername][target][label]
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    numberFormat(val) {
      return lou.curency(val)
    },
  },
}
</script>

<style>
.ant-card-body {
  padding: 24px !important;
}
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
